import { ComponentProps } from 'react';
import { Menu as LibMenu } from 'antd';
import clsx from 'clsx';
import styles from './Menu.module.less';

interface MenuProps extends ComponentProps<typeof LibMenu> {
    round?: boolean;
}

const Menu = ({
    children,
    className,
    inlineIndent,
    round,
    ...restProps
}: MenuProps) => {
    if (inlineIndent === undefined && round) {
        inlineIndent = 19;
    }
    return (
        <LibMenu
            className={clsx(
                styles.menuBase,
                { [styles.round]: round },
                className
            )}
            inlineIndent={inlineIndent}
            {...restProps}
        >
            {children}
        </LibMenu>
    );
};

Menu.Item = LibMenu.Item;
Menu.ItemGroup = LibMenu.ItemGroup;
Menu.SubMenu = LibMenu.SubMenu;

export { Menu };
