import { ComponentProps, FC } from 'react';
import { Space as LibSpace } from 'antd';

interface SpaceProps extends ComponentProps<typeof LibSpace> {
    between?: boolean;
    fullWidth?: boolean;
}

const Space: FC<SpaceProps> = ({
    between,
    fullWidth,
    style = {},
    ...restProps
}) => {
    const { direction } = restProps;
    if (direction === 'vertical') {
        style.display = 'flex';
    } else if (between) {
        style.justifyContent = 'space-between';
    }
    if (fullWidth) {
        style.width = '100%';
    }
    return <LibSpace style={style} {...restProps} />;
};

export { Space };
