import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useScrollSpy from 'react-use-scrollspy';
import { BaseHeader, BaseHeaderRef, Button, Menu } from '@aml/shared';
import { LanguageSelect } from '../LanguageSelect';
import { useScrollTo } from '../helpers';

interface HeaderProps {
    breakpoint?: number;
}

const Header = ({ breakpoint }: HeaderProps) => {
    const host: string = window.location.href.includes('infin-it')
        ? 'https://aml-cloud.infin-it.kz'
        : 'https://podft.ufinance.kz';
    const [t] = useTranslation();
    const scrollTo = useScrollTo();
    const [sectionElementRefs, setSectionElementRefs] = useState<
        RefObject<HTMLElement>[]
    >([]);
    const activeSection = useScrollSpy({
        activeSectionDefault: -1,
        sectionElementRefs,
        offsetPx: -140,
    });
    const headerRef = useRef<BaseHeaderRef | null>();
    const onMenuClick = ({ key }: { key: any }) => {
        scrollTo(key);
        headerRef.current?.closeMenu();
    };
    useEffect(() => {
        setSectionElementRefs([
            { current: document.getElementById('why') },
            { current: document.getElementById('about') },
            { current: document.getElementById('partner') },
        ]);
    }, [setSectionElementRefs]);
    const navigation = (isMobile: boolean) => (
        <Menu
            mode={isMobile ? 'inline' : 'horizontal'}
            selectedKeys={
                activeSection !== -1
                    ? [['why', 'about', 'partner'][activeSection]]
                    : []
            }
            onClick={onMenuClick}
        >
            <Menu.Item key="about">
                {t('#', { defaultValue: 'Как это работает?' })}
            </Menu.Item>
            <Menu.Item key="partner">
                {t('#', {
                    defaultValue: 'С нами удобно',
                })}
            </Menu.Item>
        </Menu>
    );
    const actions = (isMobile: boolean) => (
        <>
            <LanguageSelect
                fullWidth={isMobile}
                size={isMobile ? 'large' : 'middle'}
            />
            {/* <Button
                type="primary"
                size={isMobile ? 'large' : 'middle'}
                block={isMobile}
            >
                <a href={host + '/aml/'} rel="noreferrer">
                    {t('landing.nav_cabinet', {
                        defaultValue: 'Личный кабинет',
                    })}
                </a>
            </Button> */}
        </>
    );
    return (
        <BaseHeader
            ref={(ref) => {
                headerRef.current = ref;
            }}
            breakpoint={breakpoint}
            navigation={navigation}
            actions={actions}
        />
    );
};

export { Header };
