import { Row } from 'antd';
import Logo from '../../../assets/images/ufinance_logo_light.svg';
import styles from './Footer.module.less';
import clsx from 'clsx';

const Footer = ({ isMobile }) => {
    return (
        <Row className={clsx(styles.footer)}>
            <div className={clsx(styles.footerWrapper)}>
                <div className={clsx(styles.footerAction)}>
                    <div className={clsx(styles.logoContainer)}>
                        <img src={Logo} alt="" />
                    </div>
                    <div>
                        <button
                            className={clsx(styles.button)}
                            onClick={() => {
                                const element = document.getElementById(
                                    'Request'
                                );
                                element &&
                                    element.scrollIntoView({
                                        behavior: 'smooth',
                                    });
                            }}
                        >
                            Заполнить анкету
                        </button>
                    </div>
                </div>
                <div className={clsx(styles.footerInfo)}>
                    <div style={{ color: '#74C11D' }}>
                        <a
                            href={process.env.PUBLIC_URL + '/policy.pdf'}
                            rel="noreferrer"
                            target="_blank"
                        >
                            Политика конфиденциальности
                        </a>
                    </div>
                    <div
                        className={clsx(styles.paragraph)}
                        style={
                            isMobile
                                ? { width: '100%', fontSize: 12 }
                                : { width: 650 }
                        }
                    >
                        Персональный подбор кредита — сервис, который бесплатно
                        подбирает кредитные предложения с учетом ваших кредитных
                        потребностей и лучшие предложения онлайн
                    </div>
                    <div
                        className={
                            isMobile
                                ? clsx(styles.requisitesMobile)
                                : clsx(styles.requisites)
                        }
                    >
                        <div>ТОО "Ufinance" - 2021</div>
                        <div>БИН 201140030990</div>
                        <div>Юр.адрес: ул. Таттимбетова 390</div>
                        <div>Email: info@ufinance.kz</div>
                    </div>
                </div>
            </div>
        </Row>
    );
};

export { Footer };
