import { ComponentProps, CSSProperties, FC } from 'react';
import { Skeleton as LibSkeleton } from 'antd';
import styles from './Skeleton.module.less';

interface SkeletonProps extends ComponentProps<typeof LibSkeleton> {}

const Skeleton = (props: SkeletonProps) => <LibSkeleton {...props} />;

interface TableSkeletonProps {
    active?: boolean;
    rows?: number;
}

const TableSkeleton = ({ rows = 6, ...restProps }: TableSkeletonProps) => {
    return (
        <Skeleton
            title={false}
            paragraph={{ rows, width: '100%', className: styles.table }}
            {...restProps}
        />
    );
};

interface FieldSkeletonProps {
    active?: boolean;
    labelStyle?: CSSProperties;
    fieldStyle?: CSSProperties;
}

const FieldSkeleton = ({
    labelStyle,
    fieldStyle,
    active,
}: FieldSkeletonProps) => {
    return (
        <Skeleton
            title={{ className: styles.fieldLabel, style: labelStyle }}
            paragraph={{
                rows: 1,
                width: '100%',
                className: styles.field,
                style: fieldStyle,
            }}
            active={active}
        />
    );
};

type FieldsSkeletonProps = FieldSkeletonProps & {
    rows?: number;
};

const FieldsSkeleton = ({ rows = 1, ...restProps }: FieldsSkeletonProps) => {
    return (
        <>
            {Array.from(Array(rows)).map((_, key) => (
                <FieldSkeleton key={key} {...restProps} />
            ))}
        </>
    );
};

Skeleton.Avatar = LibSkeleton.Avatar;
Skeleton.Button = LibSkeleton.Button;
Skeleton.Input = LibSkeleton.Input;
Skeleton.Image = LibSkeleton.Image;
Skeleton.Table = TableSkeleton;
Skeleton.Field = FieldSkeleton;
Skeleton.Fields = FieldsSkeleton;

export { Skeleton };
