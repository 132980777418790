import React, { ComponentProps } from 'react';
import clsx from 'clsx';
import { Card as LibCard } from 'antd';
import styles from './Card.module.less';

interface CardProps extends ComponentProps<typeof LibCard> {
    titleBordered?: boolean;
    elevated?: boolean;
    wideTable?: boolean;
    footer?: React.ReactNode;
    footerClassName?: string;
    contentClassName?: string;
}

const Card = (props: CardProps) => {
    const {
        elevated,
        titleBordered = true,
        wideTable = true,
        footer,
        footerClassName,
        contentClassName,
        className,
        children,
        ...rest
    } = props;
    return (
        <LibCard
            className={clsx(
                styles.cardBase,
                {
                    [styles.cardBaseFlex]: footer != null,
                    [styles.elevated]: elevated,
                    [styles.wideTable]: wideTable,
                    [styles.titleBorderless]: titleBordered === false,
                },
                className
            )}
            {...rest}
        >
            {footer ? (
                <>
                    <div className={clsx(styles.cardContent, contentClassName)}>
                        {children}
                    </div>
                    <div className={clsx(styles.cardFooter, footerClassName)}>
                        {footer}
                    </div>
                </>
            ) : (
                children
            )}
        </LibCard>
    );
};

Card.Grid = LibCard.Grid;
Card.Meta = LibCard.Meta;

export { Card };
