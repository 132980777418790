import React from 'react';
import { Row } from 'antd';
import styles from './HowItWorks.module.less';
import clsx from 'clsx';

const HowItWorks: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    return (
        <>
            <Row className={clsx(styles.howContainer)}>
                <div className={clsx(styles.howWrapper)}>
                    <h1 className={clsx(styles.howTitle)}>
                        Как работает сервис <span>Ufinance?</span>
                    </h1>
                    <Row className={clsx(styles.howItem)}>
                        <span>1</span>
                        <p>Заполните нужную сумму и срок</p>
                        <div className={clsx(styles.howTicks1)}></div>
                    </Row>
                    <Row className={clsx(styles.howItem)}>
                        <span>2</span>
                        <p>Заполните короткую анкету</p>
                        <div className={clsx(styles.howTicks2)}></div>
                    </Row>
                    <Row className={clsx(styles.howItem)}>
                        <span>3</span>
                        <p>Получите выгодные предложения от банков</p>
                        <div className={clsx(styles.howTicks3)}></div>
                    </Row>
                    <div
                        className={clsx(
                            isMobile
                                ? styles.buttonContainerMobile
                                : styles.buttonContainer
                        )}
                    >
                        <button
                            className={clsx(styles.howButton)}
                            onClick={() => {
                                const element = document.getElementById(
                                    'Request'
                                );
                                element &&
                                    element.scrollIntoView({
                                        behavior: 'smooth',
                                    });
                            }}
                        >
                            Заполнить анкету
                        </button>
                    </div>
                </div>
            </Row>
        </>
    );
};

export { HowItWorks };
