import clsx from 'clsx';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './Loader.module.less';

interface LoaderProps {
    centered?: boolean;
}

const Loader = ({ centered }: LoaderProps) => (
    <div className={clsx({ [styles.centered]: centered })}>
        <LoadingOutlined className={clsx(styles.spinner)} spin />
    </div>
);

export { Loader };
