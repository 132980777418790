import { FC, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd';
import antdRu from 'antd/lib/locale/ru_RU';
import { Loader } from '../components';
import { HttpProvider } from './http';

antdRu.DatePicker!.lang.locale = 'ru';

interface BootstrapProps {
    fallback?: React.ReactNode;
}

const Bootstrap: FC<BootstrapProps> = ({
    fallback = <Loader centered />,
    children,
}) => {
    return (
        <RecoilRoot>
            <I18nextProvider i18n={i18next}>
                <ConfigProvider locale={antdRu}>
                    <Suspense fallback={fallback}>
                        <HttpProvider />
                        {children}
                    </Suspense>
                </ConfigProvider>
            </I18nextProvider>
        </RecoilRoot>
    );
};

export { Bootstrap };
