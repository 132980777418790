import { useCallback } from 'react';
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import Cookies from 'js-cookie';

interface User {
    id: number;
    email: string;
    login: string;
    name: string;
    passwordMustBeChanged: boolean;
    role: any;
    priveleges: string[];
    routes: string[];
    customerId: number;
    customerName: string;
}

interface Customer {
    customerId: number;
    customerName: string;
    customerShortName: string;
    email: string;
    firstName: string;
    id: number;
    login: string;
    roles: Role[];
    statusCode: string;
    statusDescription: string;
}

interface Priveleges {
    authority: string;
    code: string;
    description: string;
    id: number;
    method: string;
}

interface Role {
    id: number;
    code: string;
    description: string;
    name: string;
    systemGroup: string;
}

const __cookieKey__ = 'JSESSIONID';

const userQueryRequestIdState = atom({
    key: 'UserQueryRequestId',
    default: 0,
});

const userQuery = selector({
    key: 'UserQuery',
    get: async ({ get }) => {
        get(userQueryRequestIdState);
        if (Cookies.get(__cookieKey__)) {
            try {
                const { data: user } = await axios.get<User>(`users/me`);
                const { data: roles } = await axios.get<Role[]>(
                    `access/roles/my`
                );
                const { data: priveleges } = await axios.get<Priveleges[]>(
                    'access/authorities/my'
                );
                const { data: customer } = await axios.get<Customer>(
                    `users/me`,
                    { baseURL: '/api/v2' }
                );
                user.role = roles.map((role: Role) => role.code);
                user.priveleges = priveleges.map(
                    (privelege: Priveleges) => privelege.code
                );
                user.customerId = customer.customerId;
                user.customerName = customer.customerName;
                return user;
            } catch (err) {
                console.log(err);
            }
        }
        return null;
    },
});

const authenticatedState = selector<boolean>({
    key: 'Authenticated',
    get: ({ get }) => {
        return (
            get(userQuery) !== null &&
            get(passwordChangeRequiredState) === false
        );
    },
    set: ({ set }, value) => {
        if (value === false) {
            Cookies.remove(__cookieKey__);
            set(userQueryRequestIdState, (requestId) => requestId + 1);
        }
    },
});

const passwordChangeRequiredState = selector({
    key: 'PasswordChangeRequired',
    get: ({ get }) => {
        return get(userQuery)?.passwordMustBeChanged ?? false;
    },
});

const useAuthActions = () => {
    const setUserQueryRequestId = useSetRecoilState(userQueryRequestIdState);
    const setAuthenticatedState = useSetRecoilState(authenticatedState);
    const getUser = useCallback(() => {
        setUserQueryRequestId((requestId) => requestId + 1);
    }, [setUserQueryRequestId]);
    const clearUser = useCallback(() => {
        setAuthenticatedState(false);
    }, [setAuthenticatedState]);
    const login = useCallback(
        async (creds: {
            username: string;
            password: string;
            rememberMe?: boolean;
        }) => {
            const formData = new FormData();
            formData.append('username', creds.username);
            formData.append('password', creds.password);
            formData.append(
                'remember-me',
                creds.rememberMe?.toString() ?? 'false'
            );
            await axios.post('/api/login', formData, { baseURL: '' });
            getUser();
        },
        [getUser]
    );
    const logout = useCallback(
        async (skipClear: boolean = false) => {
            try {
                await axios.post(`/api/logout`, null, { baseURL: '' });
            } catch (err) {
                console.error(err);
            }
            if (skipClear !== true) {
                clearUser();
            }
        },
        [clearUser]
    );
    return { getUser, clearUser, login, logout };
};

const useAuth = () => {
    const user = useRecoilValue(userQuery);
    const authenticated = useRecoilValue(authenticatedState);
    const passwordChangeRequired = useRecoilValue(passwordChangeRequiredState);
    const actions = useAuthActions();
    return {
        user,
        authenticated,
        passwordChangeRequired,
        ...actions,
    };
};

export { useAuth, useAuthActions };
