import { FC, useEffect } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

const componentPortalOutletState = atomFamily<React.ReactNode[], string>({
    key: 'componentPortalOutlet',
    default: [],
});

interface ComponentPortalOutletProps {
    name: string;
}

const ComponentPortalOutlet: FC<ComponentPortalOutletProps> = (props) => {
    const { name, children } = props;
    const outletChildren = useRecoilValue(componentPortalOutletState(name));
    return <>{outletChildren?.[0] ?? children}</>;
};

interface ComponentPortalProps {
    name: string;
}

const ComponentPortal: FC<ComponentPortalProps> = (props) => {
    const { name, children } = props;
    const setOutletChildren = useSetRecoilState(
        componentPortalOutletState(name)
    );
    useEffect(() => {
        if (children) {
            setOutletChildren((outletChildren) => [
                children,
                ...outletChildren,
            ]);
            return () => {
                setOutletChildren((outletChildren) =>
                    outletChildren.filter((item) => item !== children)
                );
            };
        }
    }, [name, children]);
    return null;
};

const Portal = {
    ComponentPortalOutlet,
    ComponentPortal,
};

export { Portal };
