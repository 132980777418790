import { ComponentProps } from 'react';
import { Badge as LibBadge } from 'antd';
import clsx from 'clsx';
import styles from './Badge.module.less';

interface BadgeProps extends ComponentProps<typeof LibBadge> {
    position?: '720';
}

const Badge = (props: BadgeProps) => {
    const { position, ...rest } = props;
    return (
        <LibBadge
            className={clsx(styles.badge, styles[`badgeP${position}`])}
            {...rest}
        />
    );
};

export { Badge };
