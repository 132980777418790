import { Card, Input, Row, Col, Modal } from 'antd';
import clsx from 'clsx';
import { Form } from '@aml/shared';
import styles from './Request.module.less';
import { ChangeEvent, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Cookies from 'universal-cookie';

interface RequestProps {
    isMobile?: boolean;
    className?: string;
    titleClassName?: string;
    paragraphClassName?: string;
}

interface CalculatorState {
    sum: number;
    amount: number;
    duration: number | undefined;
    fontSize: number;
}
interface ExceptionState {
    amount: { display: string };
    duration: { display: string };
    IIN: { display: string };
    FIO: { display: string };
    phone: { display: string };
    isValid: boolean;
}

interface FormData {
    amount: string;
    duration: string | undefined;
    IIN: string;
    FIO: string;
    phone: string;
}
interface SearchParams {
    utm_source?: string;
    utm_medium?: string;
    transaction_id?: string;
    admitadUid: string;
    owner: string;
}

const Request: React.FC<RequestProps> = ({ isMobile }) => {
    const { search } = useLocation<string>();

    const [calculatorState, setCalculatorState] = useState<CalculatorState>({
        sum: 0,
        amount: 0,
        duration: 0,
        fontSize: isMobile ? 14 : 16,
    });

    const [searchParams, setSearchParams] = useState<SearchParams>();

    const [formData, setFormData] = useState<FormData>({
        amount: '',
        duration: undefined,
        IIN: '',
        FIO: '',
        phone: '',
    });

    const [exceptionState, setExceptionState] = useState<ExceptionState>({
        amount: { display: 'none' },
        duration: { display: 'none' },
        IIN: { display: 'none' },
        FIO: { display: 'none' },
        phone: { display: 'none' },
        isValid: false,
    });

    useEffect(() => {
        const cookies = new Cookies();
        let admitadUid = cookies.get('admitadUid');
        let utm_medium = cookies.get('utm_medium');
        let owner = cookies.get('utm_medium');
        if (!!search) {
            let utm_source = '';
            let transaction_id = '';
            const params = search.split('&');
            params.forEach((param) => {
                if (param.includes('utm_source=')) {
                    utm_source = param.slice(param.indexOf('=') + 1);
                }
                if (param.includes('utm_medium=')) {
                    utm_medium = param.slice(param.indexOf('=') + 1);
                    owner = utm_medium;
                    const expires = new Date();
                    expires.setDate(expires.getDate() + 30);
                    cookies.set('utm_medium', utm_medium, {
                        expires,
                    });
                }
                if (param.includes('transaction_id=')) {
                    transaction_id = param.slice(param.indexOf('=') + 1);
                }
            });

            if (utm_medium === 'admitad') {
                admitadUid = transaction_id;
                const expires = new Date();
                expires.setDate(expires.getDate() + 30);
                cookies.set('admitadUid', admitadUid, {
                    expires,
                });
            } else if (
                utm_medium &&
                utm_medium !== 'admitad' &&
                utm_medium !== null
            ) {
                admitadUid = null;
            }

            setSearchParams({
                utm_source,
                utm_medium,
                transaction_id,
                admitadUid,
                owner,
            });
        } else {
            setSearchParams({ admitadUid, owner });
        }
    }, [search]);

    useEffect(() => {
        const fontSize = isMobile
            ? calculatorState.sum > 9999999
                ? 12
                : 14
            : calculatorState.sum > 9999999
            ? 14
            : 16;
        setCalculatorState((prevState) => ({
            ...prevState,
            fontSize,
        }));
    }, [isMobile]);

    const formatAmount = (value: ChangeEvent<HTMLInputElement>): void => {
        let formattedAmount = value.target.value;
        formattedAmount = formattedAmount.replace(/ /g, '');
        formattedAmount = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        calculateSum(value);
        setFormData((prevState) => ({
            ...prevState,
            amount: formattedAmount,
        }));
    };

    const calculateSum = (value: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = value.target.value.replace(/\s/g, '');
        validateInput(value);
        const type = !!value ? value.target.name : '';
        const amount =
            value.target.name === 'amount'
                ? !!inputValue
                    ? +inputValue
                    : 0
                : calculatorState.amount;
        const duration =
            value.target.name === 'amount'
                ? calculatorState.duration
                : !!inputValue
                ? +inputValue
                : undefined;

        if (
            validateArg('amount', amount.toString()) &&
            validateArg('duration', !!duration ? duration.toString() : '')
        ) {
            const sum = Math.trunc(
                (amount * (15 / 100 / 12)) /
                    (1 -
                        Math.pow(1 + 15 / 100 / 12, !!duration ? -duration : 0))
            );
            const fontSize = isMobile
                ? sum > 999999
                    ? 12
                    : 14
                : sum > 999999
                ? 14
                : 16;
            setCalculatorState({
                sum,
                amount,
                duration,
                fontSize,
            });
        } else {
            const newObj = { ...calculatorState };
            newObj[type] = parseInt(inputValue);
            newObj['sum'] = 0;
            newObj['fontSize'] = isMobile ? 14 : 16;
            setCalculatorState(newObj);
        }
    };

    const validateInput = (value: ChangeEvent<HTMLInputElement>) => {
        if (value.target.name === 'IIN' && value.target.value.length > 12)
            value.target.value = value.target.value.slice(0, 12);
        if (validateArg(value.target.name, value.target.value)) {
            const newObj: ExceptionState = { ...exceptionState };
            let isValid = true;
            for (let key in newObj) {
                if (
                    newObj[key].display === 'block' &&
                    key !== value.target.name
                ) {
                    isValid = false;
                }
            }
            setExceptionState((prevState) => ({
                ...prevState,
                [value.target.name]: { display: 'none' },
                isValid,
            }));
        } else {
            setExceptionState((prevState) => ({
                ...prevState,
                [value.target.name]: { display: 'block' },
                isValid: false,
            }));
        }
    };

    const validateArg = (type: string, argument: string): boolean => {
        switch (type) {
            case 'amount':
                const inputValue = argument.replace(/\s/g, '');
                if (+inputValue >= 100000 && +inputValue <= 7000000) {
                    return true;
                }
                return false;
            case 'duration':
                setFormData((prevState) => ({
                    ...prevState,
                    duration: argument,
                }));
                if (+argument >= 1 && +argument <= 60) {
                    return true;
                }
                return false;
            case 'IIN':
                setFormData((prevState) => ({
                    ...prevState,
                    IIN: argument,
                }));
                if (argument.length === 12) {
                    return true;
                }
                return false;
            case 'FIO':
                if (argument.match('^[a-zA-ZА-Яа-я ]*$') !== null) {
                    setFormData((prevState) => ({
                        ...prevState,
                        FIO: argument,
                    }));
                }
                if (
                    argument.trim().split(' ').length > 1 &&
                    argument.trim().split(' ').length < 4
                ) {
                    return true;
                }
                return false;
            case 'phone':
                setFormData((prevState) => ({
                    ...prevState,
                    phone: argument,
                }));
                if (!~argument.indexOf('_', 0)) {
                    return true;
                }
                return false;
            default:
                return false;
        }
    };

    const requestSuccess = () => {
        Modal.success({
            content:
                'Ваша заявка успешно принята, в ближайшее время с Вами свяжется менджер',
        });
    };

    const requestError = (message) => {
        Modal.error({
            title: 'Произошла ошибка',
            content:
                message ||
                'Извините, сервер временно недоступен, попробуйте позднее',
        });
    };

    const sendRequest = () => {
        const requestData: any = {
            fullName: formData.FIO,
            phone: formData.phone,
            taxCode: formData.IIN,
            requestedPeriodInMonths: !!formData.duration
                ? parseInt(formData.duration)
                : formData.duration,
            requestedAmount: parseInt(formData.amount.replace(/\s/g, '')),
            utmMedium: searchParams?.utm_medium,
            utmSource: searchParams?.utm_source,
            transactionId: searchParams?.transaction_id,
            owner: searchParams?.owner,
        };
        if (searchParams?.owner === 'admitad') {
            requestData.admitadUid = searchParams?.admitadUid;
        }
        if (exceptionState.isValid) {
            axios
                .post('public/requests', requestData)
                .then(function (response) {
                    if (response.status === 200) {
                        setFormData({
                            amount: '',
                            duration: undefined,
                            IIN: '',
                            FIO: '',
                            phone: '',
                        });
                        setCalculatorState({
                            sum: 0,
                            amount: 0,
                            duration: 0,
                            fontSize: isMobile ? 14 : 16,
                        });
                        requestSuccess();
                    }
                })
                .catch(function (err) {
                    requestError(err.response.data.message);
                });
        }
    };

    return (
        <>
            <div id="Request" className={clsx(styles.cardWrapper)}>
                <Card
                    bordered={false}
                    className={clsx(styles.formCard)}
                    title={
                        <h1 className={styles.requestTitle}>
                            Оставьте заявку на кредит
                        </h1>
                    }
                >
                    <Form>
                        <Row justify="space-around">
                            <Col
                                span={isMobile ? 24 : 8}
                                style={{ marginBottom: 20 }}
                            >
                                <Form.Item>
                                    <span>Сумма</span>
                                    <Input
                                        placeholder="тг"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={formatAmount}
                                    />
                                    <div
                                        className={styles.inputException}
                                        style={exceptionState.amount}
                                    >
                                        Сумма кредита не должна превышать 7 000
                                        000 тенге и быть меньше 100 000 тенге
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <span>Срок</span>
                                    <Input
                                        placeholder="Колличество месяцев"
                                        name="duration"
                                        onChange={calculateSum}
                                        type="number"
                                        value={formData.duration}
                                    />
                                    <div
                                        className={styles.inputException}
                                        style={exceptionState.duration}
                                    >
                                        Срок кредита не должен быть больше 60
                                        месяцев, и меньше 1-го месяца
                                    </div>
                                    <div
                                        className={styles.calculatorValue}
                                        style={{
                                            fontSize: calculatorState.fontSize,
                                        }}
                                    >
                                        Ежемесячный платеж:{' '}
                                        {calculatorState.sum}*
                                    </div>
                                </Form.Item>
                            </Col>
                            <div className={clsx(styles.divider)}></div>
                            <Col span={isMobile ? 24 : 8}>
                                <Form.Item>
                                    <span>ИИН</span>
                                    <Input
                                        type="number"
                                        maxLength={12}
                                        name="IIN"
                                        value={formData.IIN}
                                        onChange={validateInput}
                                    />
                                    <div
                                        className={styles.inputException}
                                        style={exceptionState.IIN}
                                    >
                                        Пожалуйста, введите 12 цифр Вашего ИИН
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <span>ФИО</span>
                                    <Input
                                        name="FIO"
                                        value={formData.FIO}
                                        onChange={validateInput}
                                    />
                                    <div
                                        className={styles.inputException}
                                        style={exceptionState.FIO}
                                    >
                                        Пожалуйста, введитите ФИО
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <span>Телефон</span>
                                    <MaskedInput
                                        mask={[
                                            '+',
                                            '7',
                                            ' ',
                                            '(',
                                            '7',
                                            /\d/,
                                            /\d/,
                                            ')',
                                            ' ',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        className="ant-input"
                                        showMask
                                        value={formData.phone}
                                        name="phone"
                                        onChange={validateInput}
                                        keepCharPositions
                                    />
                                    <div
                                        className={styles.inputException}
                                        style={exceptionState.phone}
                                    >
                                        Пожалуйста, введите номер телефона в
                                        формате + 7 7ХХ ХХХ ХХ ХХ
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div
                            className={'agreeLink'}
                            style={{
                                textAlign: 'center',
                                marginTop: 10,
                                lineHeight: '20px',
                            }}
                        >
                            <Checkbox checked /> Я согласен(-а) на обработку
                            <a
                                href={process.env.PUBLIC_URL + '/policy.pdf'}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                    verticalAlign: 'text-bottom',
                                    borderBottom: '1px solid rgba(11,31,53,.3)',
                                    marginLeft: 5,
                                    marginTop: 10,
                                }}
                            >
                                персональных данных
                            </a>
                        </div>
                        <div
                            className={clsx(
                                isMobile
                                    ? styles.buttonContainerMobile
                                    : styles.buttonContainer
                            )}
                        >
                            <button
                                className={clsx(styles.sendButton)}
                                onClick={sendRequest}
                            >
                                Отправить
                            </button>
                        </div>
                    </Form>
                </Card>
            </div>
        </>
    );
};

export { Request };
