import React from 'react';
import { Row, Col } from 'antd';
import clsx from 'clsx';
import styles from './Promo.module.less';

interface PromoProps {
    isMobile?: boolean;
    className?: string;
    titleClassName?: string;
    paragraphClassName?: string;
}

const Promo: React.FC<PromoProps> = ({ isMobile }) => {
    console.log(isMobile);
    return (
        <>
            <div className={clsx(styles.promoBackground)} />
            <Row justify="space-around" className={clsx(styles.promoWrapper)}>
                <Col span={isMobile ? 24 : 8}>
                    <div className={clsx(styles.promoLeft)}>
                        <span>U</span>Finance - <br />
                        Бесплатный сервис по подбору кредита
                    </div>
                </Col>
                <Col span={isMobile ? 24 : 8}>
                    <div className={clsx(styles.promoRight)}>
                        <Row
                            style={
                                isMobile
                                    ? { display: 'none' }
                                    : { display: 'block' }
                            }
                        >
                            <span
                                style={
                                    isMobile
                                        ? { color: '#000' }
                                        : { color: '#fff' }
                                }
                            >
                                Сэкономьте Ваше время
                            </span>
                        </Row>
                        <Row justify={isMobile ? 'center' : 'start'}>
                            Отправьте всего одну анкету в несколько банков
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export { Promo };
