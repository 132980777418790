import i18next from 'i18next';
import HttpBackend, { BackendOptions } from 'i18next-http-backend';
import LanguageDetector, {
    DetectorOptions,
} from 'i18next-browser-languagedetector';
export { useLanguages } from './useLanguages';

const bootstrapI18n = () => {
    const languageDetector = new LanguageDetector();
    languageDetector.addDetector({
        name: 'fallback',
        lookup: () => 'ru',
    });
    i18next
        .use(HttpBackend)
        .use(languageDetector)
        .init({
            load: 'languageOnly',
            fallbackLng: [],
            backend: {
                loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`,
            } as BackendOptions,
            detection: {
                order: ['localStorage', 'fallback'],
                lookupLocalStorage: '_lang_',
                caches: ['localStorage'],
            } as DetectorOptions,
        });
    i18next.on('languageChanged', (lang) => {
        document.documentElement.lang = lang;
    });
};

export { bootstrapI18n };
