import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

interface LanguageSelectProps {
    size?: 'large' | 'middle' | 'small';
    fullWidth?: boolean;
}

const LanguageSelect = ({ size, fullWidth }: LanguageSelectProps) => {
    const [, i18next] = useTranslation();
    return (
        <Select
            style={{ width: fullWidth ? '100%' : 80 }}
            size={size}
            defaultValue={i18next.language}
            bordered={false}
            onChange={(lang) => {
                i18next.changeLanguage(lang);
            }}
        >
            <Select.Option value="kk">KZ</Select.Option>
            <Select.Option value="ru">RU</Select.Option>
        </Select>
    );
};

export { LanguageSelect };
