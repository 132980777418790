import {
    createContext,
    ComponentProps,
    useMemo,
    useContext,
    useEffect,
    FC,
    useState,
    useCallback,
} from 'react';
import { useLocation, matchPath, NavLink } from 'react-router-dom';
import { useMap } from 'react-use';
import { Menu } from '../components';

interface NavMenuContextValue {
    addKey: (key: string, values?: string[]) => void;
}

const NavMenuContext = createContext<NavMenuContextValue | null>(null);

type NavMenuProps = ComponentProps<typeof Menu> & {
    pathMatcher?: (currentPathname: string, pathnames: string[]) => string[];
};

const defaultPathMatcher = (currentPathname: string, pathnames: string[]) => {
    return [
        pathnames
            .sort((a, b) => b.length - a.length)
            .find((path) => {
                const exact = ['/', ''].includes(path.trim());
                return matchPath(currentPathname, { path, exact });
            }) ?? '',
    ];
};

const NavMenu = (props: NavMenuProps) => {
    const { pathMatcher = defaultPathMatcher, ...restProps } = props;
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [keys, { set }] = useMap<{ [path: string]: Set<string> }>();
    const value = useMemo(() => {
        return {
            addKey: (key: string, values: string[] = []) => {
                if (!keys[key] || keys[key].size !== values.length) {
                    const valueSet = keys[key] ?? new Set();
                    for (const value of values) {
                        valueSet.add(value);
                    }
                    set(key, valueSet);
                }
            },
        };
    }, [keys, set]);
    const { pathname } = useLocation();
    const selectedKeys = useMemo(() => {
        const pathnames = Object.keys(keys);
        return pathMatcher?.(pathname, pathnames);
    }, [pathname, keys]);
    const onOpenChange = useCallback(
        (openKeys: any[], add: boolean = false) => {
            setOpenKeys((state) => {
                if (add) {
                    openKeys = [...state, ...openKeys];
                }
                const openKeysSet = new Set<string>();
                for (const openKey of openKeys) {
                    openKeysSet.add(openKey);
                }
                return Array.from(openKeysSet);
            });
        },
        [setOpenKeys]
    );
    useEffect(() => {
        const openKeys = selectedKeys.reduce<string[]>((p, c) => {
            return [...p, ...Array.from(keys[c] ?? [])];
        }, []);
        onOpenChange(openKeys, true);
    }, [onOpenChange, selectedKeys]);
    return (
        <NavMenuContext.Provider value={value}>
            <Menu
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                forceSubMenuRender
                {...restProps}
            />
        </NavMenuContext.Provider>
    );
};

const useNavMenuKeys = (eventKey: string) => {
    const navMenuKeys = useContext(NavMenuContext);
    useEffect(() => {
        if (eventKey && navMenuKeys) {
            navMenuKeys.addKey(eventKey);
        }
    }, [navMenuKeys, eventKey]);
};

type NavMenuSubMenuProps = ComponentProps<typeof Menu.SubMenu> & {
    eventKey?: string;
};

const NavMenuSubMenu: FC<NavMenuSubMenuProps> = (props) => {
    const { eventKey } = props;
    const navMenuKeys = useContext(NavMenuContext);
    const value = useMemo(() => {
        return {
            addKey: (key: string, values: string[] = []) => {
                if (eventKey) {
                    navMenuKeys?.addKey(key, [...values, eventKey]);
                }
            },
        };
    }, [navMenuKeys, eventKey]);
    return (
        <NavMenuContext.Provider value={value}>
            <Menu.SubMenu popupOffset={[0, 0]} {...props} />
        </NavMenuContext.Provider>
    );
};

type NavMenuItemProps = ComponentProps<typeof Menu.Item> & { to?: string };

const NavMenuItem = ({
    to,
    eventKey,
    children,
    ...menuProps
}: NavMenuItemProps) => {
    if (process.env.NODE_ENV !== 'production') {
        if (!eventKey && !to) {
            console.warn('NavMenuItem requires `key` or `to` props to be set.');
        }
    }
    eventKey = to ?? (eventKey as string);
    useNavMenuKeys(eventKey);
    return (
        <Menu.Item
            eventKey={eventKey}
            {...menuProps}
            children={to ? <NavLink to={to} children={children} /> : children}
        />
    );
};

NavMenu.ItemGroup = Menu.ItemGroup;
NavMenu.SubMenu = NavMenuSubMenu;
NavMenu.Item = NavMenuItem;

export { NavMenu };
