import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Typography } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import axios, { AxiosResponse } from 'axios';
import { Form } from '@aml/shared';
import '@aml/shared/src/styles/custom.less';

const { Text } = Typography;
interface UploadModalFormProps {
    isOpen: boolean;
    handleCancel: (changed?: boolean) => void;
    modalTitle: string;
    resource: string;
    formats: string[];
    customValidation?: (res: AxiosResponse) => string;
}

const UploadModal = ({
    isOpen,
    handleCancel,
    modalTitle,
    resource,
    formats,
    customValidation,
}: UploadModalFormProps) => {
    const { handleSubmit, register, setValue } = useForm<any>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [fileDescription, setFileDescription] = useState<string>('');

    const formatsValidator = (formats: string[], filename: string): boolean => {
        const format: string[] = filename.split('.');
        return formats.includes(format[format.length - 1]);
    };

    const onSubmit = handleSubmit(async (values) => {
        setSubmitting(true);
        const file: UploadFile = values.fileList[0];
        if (file && formatsValidator(formats, file.name)) {
            try {
                setErrorMessage('');
                const formData = new FormData();
                formData.append('file', values.fileList[0]);

                await axios({
                    method: 'POST',
                    url: resource,
                    data: formData,
                }).then((response: AxiosResponse) => {
                    if (customValidation) {
                        setSuccessMessage(customValidation(response));
                        setTimeout(() => {
                            handleCancel(true);
                            setFileDescription('');
                            setSuccessMessage('');
                        }, 1000);
                    } else {
                        setSuccessMessage(
                            'Файл был успешно загружен, дождитесь обработки заявок'
                        );
                        setTimeout(() => {
                            handleCancel(true);
                            setFileDescription('');
                            setSuccessMessage('');
                        }, 1000);
                    }
                });
            } catch (error) {
                setErrorMessage(error.message);
                setTimeout(() => handleCancel(true), 1000);
            } finally {
                setSubmitting(false);
                setValue('fileList', null);
            }
        } else if (!file) {
            setErrorMessage('Выберите файл');
            setSubmitting(false);
        } else {
            setErrorMessage(`Разрешенные форматы: ${formats.join(' ')}`);
            setSubmitting(false);
        }
    });

    useEffect(() => {
        setSuccessMessage('');
    }, []);

    return (
        <Modal
            visible={isOpen}
            onCancel={() => {
                handleCancel();
                setErrorMessage('');
                setValue('fileList', null);
                setSuccessMessage('');
                setFileDescription('');
            }}
            title={modalTitle}
            onOk={onSubmit}
            okButtonProps={{ loading: submitting }}
            okText="Загрузить файл"
        >
            <Form onFinish={onSubmit as any}>
                <Form.Item>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <label htmlFor="file" className="custom_upload">
                                Выбрать файл
                                <input
                                    id="file"
                                    type="file"
                                    name="fileList"
                                    ref={register}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        if (e.target.files?.length) {
                                            setFileDescription(
                                                e.target.files[0].name
                                            );
                                        }
                                    }}
                                />
                            </label>
                        </div>
                        <div
                            style={{
                                marginLeft: 10,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: 250,
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {fileDescription}
                        </div>
                    </div>
                </Form.Item>
                {errorMessage.length ? (
                    <Form.Item>
                        <Text type="danger">{errorMessage}</Text>
                    </Form.Item>
                ) : null}
                {successMessage.length ? (
                    <Form.Item>
                        <Text type="success">{successMessage}</Text>
                    </Form.Item>
                ) : null}
            </Form>
        </Modal>
    );
};

export { UploadModal };
