import { ComponentProps, forwardRef } from 'react';
import { Button as LibButton } from 'antd';
import { ButtonType } from 'antd/lib/button';
import clsx from 'clsx';
import styles from './Button.module.less';

interface ButtonProps extends Omit<ComponentProps<typeof LibButton>, 'type'> {
    type?: ButtonType | 'backlit' | 'text-light' | 'ghost-primary';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { className, type, children, ...rest } = props;
    let overriddenType = type;
    if (type === 'backlit') {
        overriddenType = 'default';
    } else if (type === 'text-light') {
        overriddenType = 'text';
    } else if (type === 'ghost-primary') {
        overriddenType = 'ghost';
    }
    return (
        <LibButton
            ref={ref}
            className={clsx(
                {
                    [styles.backlit]: type === 'backlit',
                    [styles.textLight]: type === 'text-light',
                    [styles.ghostPrimary]: type === 'ghost-primary',
                },
                className
            )}
            type={overriddenType as ButtonType}
            {...rest}
        >
            {children}
        </LibButton>
    );
});

export { Button };
