import { ComponentProps } from 'react';
import { Form as LibForm, Typography } from 'antd';
import clsx from 'clsx';
import styles from './Form.module.less';

export interface FormProps extends ComponentProps<typeof LibForm> {}

const Form = ({ className, ...restProps }: FormProps) => {
    return <LibForm className={clsx(styles.form, className)} {...restProps} />;
};

export interface FormItemProps extends ComponentProps<typeof LibForm.Item> {
    labelAction?: React.ReactNode;
    errors?: string | string[];
}

const FormItem = (props: FormItemProps) => {
    const {
        errors,
        help,
        validateStatus,
        children,
        label,
        labelAction,
        ...rest
    } = props;
    return (
        <>
            {label && labelAction && (
                <div className={styles.labelAction}>
                    {typeof label === 'string' ? (
                        <Typography.Text>{label}</Typography.Text>
                    ) : (
                        label
                    )}
                    {labelAction}
                </div>
            )}
            <LibForm.Item
                {...rest}
                label={label && labelAction ? null : label}
                help={errors ?? help}
                validateStatus={errors ? 'error' : validateStatus}
            >
                {children}
            </LibForm.Item>
        </>
    );
};

Form.Item = FormItem;

export { Form };
