import { ComponentProps, createContext, forwardRef, useContext } from 'react';
import { Grid, Menu, Divider, Dropdown, Tooltip } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Button } from './Button';
import { Space } from './Space';
import styles from './Actions.module.less';
import { useHistory } from 'react-router-dom';

type Breakpoints = keyof ReturnType<typeof Grid.useBreakpoint>;

interface ActionsContextValue {
    collapsed?: boolean;
    bordered?: boolean;
}

interface ActionsProps extends ActionsContextValue {
    breakpoint?: Breakpoints;
    children?: React.ReactNode;
    dropdownTrigger?: ComponentProps<typeof Dropdown>['trigger'];
}

const ActionsContext = createContext<ActionsContextValue>({
    collapsed: false,
    bordered: true,
});

const Actions = ({
    bordered = true,
    breakpoint = 'xl',
    dropdownTrigger = ['hover'],
    children,
    ...restProps
}: ActionsProps) => {
    const breakpoints = Grid.useBreakpoint();
    const collapsed = restProps.collapsed || !breakpoints[breakpoint];
    return (
        <ActionsContext.Provider value={{ collapsed, bordered }}>
            {collapsed ? (
                <Dropdown
                    trigger={dropdownTrigger}
                    overlay={<Menu>{children}</Menu>}
                >
                    <ActionButton bordered={bordered} icon={<MoreOutlined />} />
                </Dropdown>
            ) : (
                <Space>{children}</Space>
            )}
        </ActionsContext.Provider>
    );
};

interface BaseActionItemProps extends ComponentProps<typeof Menu.Item> {
    raw?: boolean;
    tooltip?: React.ReactNode;
    children: (
        context: ActionsContextValue,
        menuProps: ComponentProps<typeof Menu.Item>
    ) => React.ReactNode;
}

const BaseActionItem = ({
    raw,
    tooltip,
    children,
    ...menuProps
}: BaseActionItemProps) => {
    const value = useContext(ActionsContext);
    const { collapsed } = value;
    const item = children(value, menuProps);
    return (
        <>
            {!collapsed && tooltip ? (
                <Tooltip title={tooltip}>{item}</Tooltip>
            ) : !raw ? (
                <Menu.Item {...menuProps}>{item}</Menu.Item>
            ) : (
                item
            )}
        </>
    );
};

interface ActionItemProps extends ComponentProps<typeof Menu.Item> {
    icon: React.ReactNode;
    label: React.ReactNode;
    onItemClick?: () => void;
}

const ActionItem = (props: ActionItemProps) => {
    /* https://github.com/ant-design/ant-design/issues/4853#issuecomment-553458296 */
    const { icon, label, onItemClick, onClick, ...menuItemProps } = props;
    return (
        <BaseActionItem
            tooltip={label}
            icon={icon}
            onClick={async (e) => {
                // Handle default menu-item click
                onClick?.(e);
                await onItemClick?.();
            }}
            {...menuItemProps}
        >
            {({ collapsed, bordered }) =>
                collapsed ? (
                    label
                ) : (
                    <ActionButton
                        bordered={bordered}
                        icon={icon}
                        onClick={onItemClick}
                    />
                )
            }
        </BaseActionItem>
    );
};

type ActionNavItemProps = Omit<ActionItemProps, 'onItemClick'> & {
    to: (() => string) | string;
};

const ActionNavItem = ({ to, ...restProps }: ActionNavItemProps) => {
    const history = useHistory();
    return (
        <ActionItem
            onItemClick={() => {
                history.push(typeof to === 'function' ? to() : to);
            }}
            {...restProps}
        />
    );
};

const ActionDivider = (menuDividerProps: {}) => (
    <BaseActionItem raw>
        {({ collapsed }) =>
            collapsed ? (
                <Menu.Divider {...menuDividerProps} />
            ) : (
                <Divider type="vertical" className={styles.actionDivider} />
            )
        }
    </BaseActionItem>
);

type ActionButtonProps = ComponentProps<typeof Button> & { bordered?: boolean };

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
    ({ bordered, icon, onClick, ...restProps }, ref) => (
        <Button
            ref={ref}
            type={bordered ? 'backlit' : 'text'}
            icon={icon}
            onClick={onClick}
            {...restProps}
        />
    )
);

Actions.BaseItem = BaseActionItem;
Actions.Item = ActionItem;
Actions.NavItem = ActionNavItem;
Actions.Divider = ActionDivider;

export { Actions };
