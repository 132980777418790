import React from 'react';
import { Row, Col } from 'antd';
import { useMedia } from 'react-use';
import { Layout } from '@aml/shared';
import { Header } from './Header';
import { Promo } from './Promo';
import { Request } from './Request';
import { HowItWorks } from './HowItWorks';
import { WhyConvinient } from './WhyConvinient';
import { Partners } from './Partners';
import { Footer } from './Footer';

import styles from './Landing.module.less';

const Landing: React.FC = () => {
    const breakpoint = 991;
    const isMobile = useMedia(`(max-width: ${breakpoint}px)`);

    return (
        <>
            <Layout className={styles.layout}>
                <Header breakpoint={breakpoint} />
                <Layout.Content
                    className={styles.content}
                    style={{ overflow: 'hidden' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Promo isMobile={isMobile} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Request isMobile={isMobile} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <HowItWorks isMobile={isMobile} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <WhyConvinient isMobile={isMobile} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Partners isMobile={isMobile} />
                        </Col>
                    </Row>
                </Layout.Content>
                <Footer isMobile={isMobile} />
            </Layout>
        </>
    );
};

export { Landing };
