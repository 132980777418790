import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notification } from 'antd';
import { useAuth } from '../auth';

export interface RequestConfig extends AxiosRequestConfig {
    authorization?: string;
    delay?: number;
}

axios.defaults.baseURL = '/api/v1';

const clearInterceptorHandlers = () => {
    const reqHandlers = (axios.interceptors.request as any).handlers;
    const resHandlers = (axios.interceptors.response as any).handlers;
    reqHandlers.splice(0, reqHandlers.length);
    resHandlers.splice(0, resHandlers.length);
};

const HttpProvider: FC = ({ children }) => {
    const { authenticated, clearUser } = useAuth();
    const { t, i18n } = useTranslation();
    clearInterceptorHandlers();
    /* Set interceptors */
    /* Authorization interceptor */
    axios.interceptors.request.use((config: RequestConfig) => {
        if (config.authorization) {
            config.headers['Authorization'] = config.authorization;
        }
        return config;
    });
    axios.interceptors.response.use(
        (response) => response,
        (err: { response?: AxiosResponse }) => {
            if (authenticated && err?.response?.status === 401) {
                clearUser?.();
            }
            return Promise.reject(err);
        }
    );
    /* Server unreachable interceptor */
    axios.interceptors.response.use(
        (response) => response,
        (err: { response?: AxiosResponse; status: any }) => {
            if (!axios.isCancel(err) && err?.response?.status === undefined) {
                notification.error({
                    duration: 5,
                    message: t('', {
                        defaultValue:
                            'Проверьте интернет соединение и попробуйте еще раз',
                    }),
                });
            }
            return Promise.reject(err);
        }
    );
    /* Request delay interceptor */
    axios.interceptors.request.use((config: RequestConfig) => {
        const { delay } = config;
        if (delay) {
            return new Promise((resolve) =>
                setTimeout(() => resolve(config), delay)
            );
        }
        return config;
    });
    /* Language interceptor */
    axios.interceptors.request.use((config: RequestConfig) => {
        config.params = {
            ...config.params,
            lang: i18n.language,
        };
        return config;
    });
    return <>{children}</>;
};

export { HttpProvider };
