import { useCallback, useEffect, useRef, useState } from 'react';
import useVisibilitySensor from '@rooks/use-visibility-sensor';

const useScrollTo = () => {
    return useCallback((id: string) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top: element.offsetTop - 62,
            });
        }
    }, []);
};

interface useVisibilityOpts {
    visibilityClasses?: [string, string];
    minTopValue?: number;
    hide?: boolean;
}

const useVisibility = <T = HTMLDivElement>({
    minTopValue = 0,
    visibilityClasses = ['fade-hidden', 'fade-in'],
    hide = false,
}: useVisibilityOpts = {}) => {
    const [start, end] = visibilityClasses;
    const [visibilityClassName, setVisibilityClassName] = useState<string>(
        start
    );
    const elementRef = useRef<T | null>(null);
    const { isVisible } = useVisibilitySensor(elementRef, {
        intervalCheck: false,
        scrollCheck: true,
        scrollThrottle: 150,
        resizeCheck: true,
        partialVisibility: true,
        minTopValue,
    });
    useEffect(() => {
        if (isVisible) {
            setVisibilityClassName(end);
        } else if (hide) {
            setVisibilityClassName(start);
        }
    }, [isVisible, hide, start, end]);
    return [elementRef, visibilityClassName, isVisible] as const;
};

export { useScrollTo, useVisibility };
