import React from 'react';
import { Row, Col } from 'antd';
import clsx from 'clsx';
import styles from './WhyConvinient.module.less';

const WhyConvinient: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    return (
        <>
            <div className={clsx(styles.convinientWrapper)}>
                <h1 className={clsx(styles.convinientTitle)}>
                    Почему с нами удобно?
                </h1>
                <Row
                    justify={isMobile ? 'center' : 'space-around'}
                    className={clsx(styles.convinientContent)}
                >
                    <Col
                        span={isMobile ? 24 : 7}
                        className={clsx(styles.convinientCard)}
                    >
                        <div className={clsx(styles.cardHeader)}>
                            <p>Экономите</p>
                            <p>Свое время</p>
                        </div>
                        <div className={clsx(styles.cardContent)}></div>
                    </Col>
                    <Col
                        span={isMobile ? 24 : 7}
                        className={clsx(styles.convinientCard)}
                    >
                        <div className={clsx(styles.cardHeader)}>
                            <p>Выбираете</p>
                            <p>Лучшее предложение банков</p>
                        </div>
                        <div className={clsx(styles.cardContent)}></div>
                    </Col>
                    <Col
                        span={isMobile ? 24 : 7}
                        className={clsx(styles.convinientCard)}
                    >
                        <div className={clsx(styles.cardHeader)}>
                            <p>Заполняете</p>
                            <p>Всего одну заявку</p>
                        </div>
                        <div className={clsx(styles.cardContent)}></div>
                    </Col>
                </Row>
                <div
                    className={clsx(
                        isMobile ? styles.arrowsMobile : styles.arrows
                    )}
                ></div>
                <div
                    className={clsx(
                        isMobile
                            ? styles.buttonContainerMobile
                            : styles.buttonContainer
                    )}
                >
                    <button
                        className={clsx(styles.whyButton)}
                        onClick={() => {
                            const element = document.getElementById('Request');
                            element &&
                                element.scrollIntoView({
                                    behavior: 'smooth',
                                });
                        }}
                    >
                        Заполнить анкету
                    </button>
                </div>
            </div>
        </>
    );
};

export { WhyConvinient };
