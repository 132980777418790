import { ComponentProps, CSSProperties, FC } from 'react';
import { Layout as LibLayout } from 'antd';
import clsx from 'clsx';
import styles from './Layout.module.less';

type LayoutProps = ComponentProps<typeof LibLayout>;

const Layout = (props: LayoutProps) => {
    return <LibLayout {...props} />;
};

interface LayoutContainerProps {
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
}

const LayoutContainer: FC<LayoutContainerProps> = ({
    className,
    style,
    children,
}) => {
    return (
        <div className={clsx(styles.layoutContainer, className)} style={style}>
            {children}
        </div>
    );
};

Layout.Content = LibLayout.Content;
Layout.Header = LibLayout.Header;
Layout.Footer = LibLayout.Footer;
Layout.Sider = LibLayout.Sider;
Layout.Container = LayoutContainer;

export { Layout };
