import React from 'react';
import { Row, Carousel } from 'antd';
import styles from './Partners.module.less';
import clsx from 'clsx';
import altyn from '../../../assets/images/altyn.svg';
import vtb from '../../../assets/images/vtb.svg';
import alpha from '../../../assets/images/alpha.svg';
import sber from '../../../assets/images/sber.svg';
import eurasian from '../../../assets/images/eurasian.svg';

const Partners: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    return (
        <>
            <Row className={clsx(styles.partnersWrapper)}>
                <h1 className={clsx(styles.partnersTitle)}>Наши партнёры</h1>
                {!isMobile ? (
                    <div className={styles.partnersRow}>
                        <div className={styles.partnersItem}>
                            <img src={altyn} alt="" />
                        </div>
                        <div className={styles.partnersItem}>
                            <img src={vtb} alt="" />
                        </div>
                        <div className={styles.partnersItem}>
                            <img src={alpha} alt="" />
                        </div>
                        <div className={styles.partnersItem}>
                            <img src={sber} alt="" />
                        </div>
                        <div className={styles.partnersItem}>
                            <img src={eurasian} alt="" />
                        </div>
                    </div>
                ) : (
                    <div className={styles.partnersRow}>
                        <Carousel autoplay={false}>
                            <div>
                                <div className={clsx(styles.partnersItem)}>
                                    <img
                                        src={altyn}
                                        alt=""
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={clsx(styles.partnersItem)}>
                                    <img
                                        src={vtb}
                                        alt=""
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={clsx(styles.partnersItem)}>
                                    <img
                                        src={alpha}
                                        alt=""
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={clsx(styles.partnersItem)}>
                                    <img
                                        src={sber}
                                        alt=""
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={clsx(styles.partnersItem)}>
                                    <img
                                        src={eurasian}
                                        alt=""
                                        style={{
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                </div>
                            </div>
                        </Carousel>
                    </div>
                )}
            </Row>
        </>
    );
};

export { Partners };
