import { ComponentProps, FC } from 'react';
import { Radio } from 'antd';
import clsx from 'clsx';
import styles from './StackedRadioGroup.module.less';

interface StackedRadioGroupProps
    extends Omit<ComponentProps<typeof Radio.Group>, 'size'> {
    centered?: boolean;
}

const StackedRadioGroup: FC<StackedRadioGroupProps> & {
    Button: FC<StackedRadioGroupButtonProps>;
} = ({ children, className, centered, ...rest }) => {
    return (
        <Radio.Group
            className={clsx(
                styles.group,
                { [styles.centered]: centered },
                className
            )}
            {...rest}
        >
            {children}
        </Radio.Group>
    );
};

interface StackedRadioGroupButtonProps
    extends ComponentProps<typeof Radio.Button> {
    dimmed?: boolean;
}

const StackedRadioGroupButton: FC<StackedRadioGroupButtonProps> = ({
    children,
    dimmed,
    className,
    ...rest
}) => {
    return (
        <Radio.Button
            className={clsx(
                styles.button,
                {
                    [styles.buttonDimmed]: dimmed,
                },
                className
            )}
            {...rest}
        >
            {typeof children === 'string' ? <span>{children}</span> : children}
        </Radio.Button>
    );
};

StackedRadioGroup.Button = StackedRadioGroupButton;

export { StackedRadioGroup };
