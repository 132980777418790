import './styles/index.less';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { bootstrapI18n, Loader } from '@aml/shared';
import { BrowserRouter as Router } from 'react-router-dom';

bootstrapI18n();

ReactDOM.render(
    <Router>
        <Suspense fallback={<Loader centered />}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Suspense>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
