import { ComponentProps, forwardRef } from 'react';
import { Input } from 'antd';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import styles from './SearchBar.module.less';

interface SearchBarProps
    extends Omit<ComponentProps<typeof Input.Search>, 'bordered' | 'prefix'> {}

const SearchBar = forwardRef<Input, SearchBarProps>(
    ({ className, ...restProps }: SearchBarProps, ref) => {
        return (
            <Input.Search
                ref={ref}
                className={clsx(styles.searchInput, className)}
                prefix={<SearchIcon color="#92929D" />}
                bordered={false}
                placeholder="по ФИО клиента или ИИН"
                enterButton="Поиск"
                {...restProps}
            />
        );
    }
);

export { SearchBar };
