import { forwardRef, useEffect, useRef, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Divider, Drawer, Typography } from 'antd';
import { Button, Menu, Space } from '@aml/shared';
import { useWindowScroll, useMedia } from 'react-use';
import clsx from 'clsx';
import { Layout } from '../Layout';
import { ReactComponent as Logo } from '../../assets/images/ufinance_logo.svg';
import styles from './BaseHeader.module.less';

export type BaseHeaderRef = { closeMenu: () => void };

type BaseHeaderRenderProp = (isMobile: boolean) => React.ReactNode;

interface BaseHeaderProps {
    className?: string;
    logo?: React.ReactNode;
    breakpoint?: number;
    navigation?: BaseHeaderRenderProp;
    contacts?: BaseHeaderRenderProp;
    actions?: BaseHeaderRenderProp;
}

const BaseHeader = forwardRef<BaseHeaderRef, BaseHeaderProps>(
    (
        {
            className,
            logo = <Logo display="block" height={36} width={180} />,
            breakpoint = 991,
            navigation,
            contacts = () => (
                <>
                    <Typography.Text>info@ufinance.kz</Typography.Text>
                    <Typography.Text>+7 (771) 506 35 13</Typography.Text>
                </>
            ),
            actions,
        },
        ref
    ) => {
        const bodyRef = useRef<HTMLBodyElement | null>();
        const [menuOpen, setMenuOpen] = useState(false);
        const isMobile = useMedia(`(max-width: ${breakpoint}px)`);
        const { y } = useWindowScroll();

        useEffect(() => {
            if (ref && typeof ref === 'function') {
                ref({
                    closeMenu: () => {
                        setMenuOpen(false);
                    },
                });
            }
        }, [ref, setMenuOpen]);

        useEffect(() => {
            if (document) {
                bodyRef.current = document.querySelector('body');
            }
        }, [bodyRef]);

        useEffect(() => {
            if (!isMobile && menuOpen) {
                setMenuOpen(false);
            }
            if (menuOpen) {
                bodyRef.current?.classList.add(styles.bodyOverflow);
            } else {
                bodyRef.current?.classList.remove(styles.bodyOverflow);
            }
        }, [bodyRef, isMobile, menuOpen]);

        const headerContacts = contacts && (
            <div className={styles.contacts}>{contacts?.(isMobile)}</div>
        );
        const headerNavigation = navigation && (
            <div className={styles.navigation}>{navigation(isMobile)}</div>
        );
        const headerActions = actions?.(isMobile);
        const collapsible =
            headerContacts != null ||
            headerNavigation != null ||
            headerActions != null;
        const collapsed = isMobile && collapsible;

        return (
            <Layout.Header
                className={clsx(styles.header, className, {
                    [styles.headerShadow]: y > 10,
                })}
            >
                <Layout.Container className={styles.content}>
                    <Space>
                        {logo}
                        {!collapsed && (
                            <>
                                <Divider type="vertical" />
                                {headerContacts}
                            </>
                        )}
                    </Space>
                    {!collapsed ? (
                        <>
                            {headerNavigation}
                            <Space style={{ marginLeft: 'auto' }}>
                                {headerNavigation && (
                                    <Divider type="vertical" />
                                )}
                                {headerActions}
                            </Space>
                        </>
                    ) : (
                        <>
                            <Drawer
                                className={styles.drawer}
                                mask={false}
                                closable={false}
                                visible={menuOpen}
                                placement="bottom"
                                height="calc(100% - 64px)"
                            >
                                {headerNavigation}
                                <Space direction="vertical" fullWidth>
                                    {headerActions}
                                    {headerContacts}
                                </Space>
                            </Drawer>
                            <Button
                                type="text"
                                className={styles.menuBtn}
                                onClick={() => setMenuOpen(!menuOpen)}
                            >
                                <MenuOutlined />
                            </Button>
                        </>
                    )}
                </Layout.Container>
            </Layout.Header>
        );
    }
);

export { BaseHeader };
