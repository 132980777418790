import { selector, useRecoilValue } from 'recoil';
import axios from 'axios';
import { Language } from '../../models';

const languages = selector({
    key: 'languages',
    get: async () => {
        const response = await axios.get<Language[]>('languages');
        return response.data;
    },
});

const useLanguages = () => {
    return useRecoilValue(languages);
};

export { useLanguages };
