import { useCallback } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

const siderMedia = '(max-width: 1200px)';

const siderOpenState = atom({
    key: 'sidebarOpen',
    default: !window.matchMedia(siderMedia).matches,
});

const useSiderActions = () => {
    const setSidebarOpen = useSetRecoilState(siderOpenState);
    const toggle = useCallback(() => {
        setSidebarOpen((open) => !open);
    }, [setSidebarOpen]);
    const close = useCallback(() => {
        setSidebarOpen(false);
    }, [setSidebarOpen]);
    const open = useCallback(() => {
        setSidebarOpen(true);
    }, [setSidebarOpen]);
    return { toggle, open, close };
};

const useSider = () => {
    const [isOpen] = useRecoilState(siderOpenState);
    const { toggle, open, close } = useSiderActions();
    return { isOpen, toggle, open, close };
};

export { useSider };
