import { createContext, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { Button, StackedRadioGroup } from '../../components';
import { useAuth } from '../../core';
import { useSider } from './services';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg';
import styles from './SidePanel.module.less';

interface SidePanelContextValue {
    setSelectedItem: (value: string) => void;
}

const SidePanelContext = createContext<SidePanelContextValue | null>(null);

interface SidePanelProps {
    addonBefore?: React.ReactNode;
    children?: React.ReactNode;
}

const SidePanel = (props: SidePanelProps) => {
    const { addonBefore, children } = props;
    const [t] = useTranslation();
    const auth = useAuth();
    const { isOpen, toggle } = useSider();

    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const [loggingOut, setLoggingOut] = useState(false);
    const logout = async () => {
        setLoggingOut(true);
        await auth.logout();
    };

    return (
        <div
            className={clsx(styles.container, { [styles.sidebarOpen]: isOpen })}
        >
            <div className={styles.addonBefore}>
                {addonBefore ?? (
                    <Button
                        block
                        type="text"
                        icon={<HamburgerIcon color="#fff" />}
                        onClick={() => toggle()}
                    />
                )}
            </div>
            <StackedRadioGroup value={selectedItem}>
                <SidePanelContext.Provider value={{ setSelectedItem }}>
                    {children}
                    <SidePanelItem
                        value="/settings"
                        title={t('', { defaultValue: 'Настройки' })}
                        dimmed
                    >
                        <SettingsIcon />
                    </SidePanelItem>
                    <SidePanelItem
                        title={t('', { defaultValue: 'Выйти' })}
                        disabled={loggingOut}
                        onClick={logout}
                        dimmed
                    >
                        <ExitIcon />
                    </SidePanelItem>
                </SidePanelContext.Provider>
            </StackedRadioGroup>
        </div>
    );
};

interface SidePanelItemProps {
    value?: string;
    /** Tooltip */
    title?: string;
    disabled?: boolean;
    dimmed?: boolean;
    onClick?: () => void;
}

const SidePanelItem: FC<SidePanelItemProps> = ({ children, ...restProps }) => {
    const { value, title, disabled, dimmed, onClick } = restProps;
    const { setSelectedItem } = useContext(SidePanelContext)!;
    children = (
        <Tooltip placement="right" title={title}>
            <StackedRadioGroup.Button
                value={value}
                disabled={disabled}
                dimmed={dimmed}
                onClick={() => {
                    !value && onClick?.();
                }}
            >
                {children}
            </StackedRadioGroup.Button>
        </Tooltip>
    );
    if (!onClick && value) {
        children = (
            <NavLink
                to={value}
                isActive={(match) => {
                    if (match) {
                        // TODO: fix https://github.com/facebook/react/issues/18178
                        setTimeout(() => {
                            setSelectedItem(value);
                        });
                    }
                    return !match;
                }}
                onClick={(e) => {
                    disabled && e.preventDefault();
                }}
            >
                {children}
            </NavLink>
        );
    }
    return <>{children}</>;
};

SidePanel.Item = SidePanelItem;

export { SidePanel };
